<template>
	<edit-template style="padding: 24rem" @confirm="submit('form')" @cancel="reset" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<div class="form-body">
			<div style="display: flex; padding: 24rem">
				<el-divider content-position="left"><h1>基础信息</h1></el-divider>
			</div>

			<el-form label-width="100rem" ref="form" class="demo-ruleForm" :model="form" :rules="rules">
				<el-form-item label="宿舍名称" prop="name">
					<el-col :span="5">
						<el-input v-model="form.name" placeholder="请输入宿舍名称"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="所属校区" prop="campus">
					<el-col :span="5">
						<el-select :popper-append-to-body="false" placeholder="请选择所属校区" v-model="form.campus" style="width: 100%" @change="campusChange">
							<el-option v-for="(i, n) of campusArr" :value="n" :label="i"></el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item label="所属楼宇" prop="buliding_id">
					<el-col :span="5">
						<el-select :popper-append-to-body="false" placeholder="请选择所属楼宇" v-model="form.buliding_id" :no-data-text="prompt" style="width: 100%" @change="bulidingChange">
							<el-option v-for="(i, n) of buildingArr" :tag="i" :value="i.id" :label="i.dimicile_buliding_name"></el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item label="楼层数" prop="floor">
					<el-col :span="5">
						<el-select :popper-append-to-body="false" placeholder="请选择所属楼层" v-model="form.floor" :no-data-text="prompt1" style="width: 100%">
							<el-option v-for="i of floorArr" :value="i" :label="i"></el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item label="居住人角色" prop="role">
					<el-col :span="5">
						<el-select :popper-append-to-body="false" placeholder="请选择居住人角色" v-model="form.role" @change="onChangeRole" style="width: 100%">
							<el-option v-for="(i, n) of role" :value="Number(n)" :label="i"></el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item label="床位数" prop="num">
					<el-col :span="5">
						<el-select :popper-append-to-body="false" placeholder="请选择床位数" v-model="form.num" style="width: 100%" @change="changeNum">
							<el-option v-for="i in beds" :value="i" :label="i"></el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-col :span="7">
						<el-input type="textarea" v-model="form.note" placeholder="请输入内容" maxlength="300" show-word-limit=""></el-input>
					</el-col>
				</el-form-item>
			</el-form>

			<div style="display: flex; padding: 24rem">
				<el-divider content-position="left"><h1>居住人</h1></el-divider>
			</div>

			<el-form ref="ruleForm" label-width="100rem" class="demo-ruleForm" @submit.native.prevent="">
				<el-form-item v-if="!form.liveinfo.length > 0">
					<el-button icon="el-icon-plus" circle="" style="margin-left: 24rem" @click="addLiveinfoTo"></el-button>
				</el-form-item>
				<el-form-item v-for="(i, n) in form.liveinfo" label-width="130rem" :label="i.live_no + '号床位'">
					<div slot="label" style="display: flex">
						<el-select :popper-append-to-body="false" size="small" v-model="form.liveinfo[n].live_no" style="width: 60rem">
							<el-option v-for="i_ in numbers" :label="i_" :value="i_"></el-option>
						</el-select>
						<span style="margin-left: 10rem">号床位</span>
					</div>
					<el-col style="display: flex">
						<!-- <el-input maxlength="10" placeholder="请输入入住人姓名" style="width: 290rem !important" v-model="i.nickName"></el-input> -->
						<el-autocomplete v-model="i.live_name" :fetch-suggestions="querySearchAsync" :popper-append-to-body="false" placeholder="请输入入住人姓名" @select="handleSelect" @blur="handleBlur" auto-complete="new-password"></el-autocomplete>
						<el-button icon="el-icon-delete-solid" circle="" style="margin-left: 24rem" @click="delLiveinfo(n)"></el-button>
						<el-button icon="el-icon-plus" circle="" style="margin-left: 24rem" @click="addLiveinfo" v-if="form.liveinfo.length - 1 == n"></el-button>
					</el-col>
				</el-form-item>
			</el-form>
		</div>
		
	
</edit-template>
</template>

<script>
import { buliding, getDormitory, getLive, putDormitory, roleYype } from './api';

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  computed: {
    numbers() {
      let res = [];
      for (let i = 1; i <= this.form.num; i++) {
        res.push(i);
      }
      return res;
    },
    selectedNum() {
      return this.form.liveinfo.map(item => item.live_no);
    },
  },
  data() {
    return {
      form: {
        buliding_id: '',
        floor: '',
        liveinfo: [{ live_name: '', live_no: 1 }],
      },
      rules: {
        name: [
          { required: true, message: '请输入宿舍名称', trigger: 'blur' },
          { min: 1, max: 10, message: '不可超过10个字！', trigger: 'blur' },
        ],
        buliding_id: [{ required: true, message: '请选择所属楼宇', trigger: 'change' }],
        campus: [{ required: true, message: '请选择所属校区', trigger: 'change' }],
        floor: [{ required: true, message: '请选择楼层数', trigger: 'change' }],
        role: [{ required: true, message: '请选择居住人角色', trigger: 'change' }],
        num: [{ required: true, message: '请选择床位数', trigger: 'change' }],
      },
      beds: ['1', '2', '4', '6', '8'],
      role: [], // 角色数组盒子
      campusArr: [], // 校区数组盒子
      buildingArr: [], // 楼宇数组盒子
      prompt: '请先选择所属校区', // 楼宇提示
      floorArr: [], // 楼层数组盒子
      prompt1: '请先选择所属楼宇', // 楼层提示
    };
  },
  methods: {
    // 宿舍居住没人的时候添加一个
    addLiveinfoTo() {
      this.form.liveinfo.push({ live_name: '', live_no: this.form.liveinfo.length + 1 });
    },
    // 楼宇变化
    bulidingChange(val) {
      this.form.floor = '';
      this.prompt1 = '暂无数据';
      this.buildingArr.forEach(i => {
        if (i.id == val) {
          this.floorArr = [];
          for (let index = i.floor_min; index <= i.floor_max; index++) {
            if ((i.floor_min == 0, i.floor_max == 0)) {
              this.floorArr.push(0);
            }
            if (index != 0) {
              this.floorArr.push(index);
            }
          }
        }
      });
    },
    // 校区改变
    campusChange(val) {
      this.form.liveinfo.forEach(item => {
        item.live_name = '';
      });
      this.form.buliding_id = '';
      this.form.floor = '';
      this.prompt = '暂无数据';
      this.$forceUpdate();
      buliding(val).then(res => {
        this.buildingArr = res.data.data;
      });
    },
    // 床位数改变
    changeNum(val) {
      if (val < this.form.liveinfo.length) {
        this.form.liveinfo.splice(val, this.form.liveinfo.length - val);
      }
    },
    // 新增床位
    addLiveinfo() {
      if (this.form.num) {
        if (this.form.liveinfo.length < this.form.num) {
          this.form.liveinfo.push({ live_no: null, live_name: '' });
        } else {
          this.$message.error('已达到最大床位数！');
        }
      } else {
        this.$message.error('请先选择床位数');
      }
    },
    // 删除床位
    delLiveinfo(n) {
      this.form.liveinfo.splice(n, 1);
      // this.form.liveinfo.forEach((item, index) => {
      // 	item.live_no = index + 1
      // })
    },
    // 保存
    submit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          // let itemId = []
          // for (let index = 0; index < this.form.liveinfo.length; index++) {
          // 	if (itemId[this.form.liveinfo[index].item.id] == null) itemId[this.form.liveinfo[index].item.id] = true
          // 	else {
          // 		this.$message.error('学生姓名不能重复！')
          // 		return
          // 	}
          // }
          let repeat = {};
          for (let item of this.form.liveinfo) {
            let re = repeat[item.live_no];
            if (re == null) {
              repeat[item.live_no] = true;
            } else {
              return this.$message.warning({
                message: '床位号重复请重新选择',
                showClose: true,
              });
            }
          }
          if (this.form.liveinfo.length > 0) {
            for (let i = 0; i < this.form.liveinfo.length; i++) {
              if (this.form.liveinfo[i].live_no > this.form.num) {
                this.$message.warning('床位号不能大于床位数');
                return false;
              }
            }
            let { created_at, buliding_name, created_by, is_man, livecount, ...data } = this.form;
            putDormitory(this.$route.query.id, data).then(res => {
              this.$notify({
                title: '修改成功',
                message: '修改宿舍成功',
                type: 'success',
              });
              this.$router.back();
            }).catch(() => { });
          } else {
            let { created_at, buliding_name, created_by, is_man, livecount, ...data } = this.form;
            putDormitory(this.$route.query.id, data).then(res => {
              this.$notify({
                title: '修改成功',
                message: '修改宿舍成功',
                type: 'success',
              });
              this.$router.back();
            }).catch(() => { });
          }
        }
      });
    },
    // 取消
    reset() {
      this.$confirm('是否取消编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.back();
      });
    },
    querySearchAsync(queryString, cb) {
      let params = {
        role: this.form.role,
        keyword: queryString,
        school: this.form.campus
      };
      getLive(params).then(res => {
        var restaurants = JSON.parse(JSON.stringify(res.data.data).replace(/name/g, 'value').replace(/id/g, 'id'));
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        });
      });
    },
    createStateFilter(queryString) {
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      this.form.liveinfo.forEach(i => {
        if (i.live_name == item.value) {
          i.item = item;
        }
      });
    },
    handleBlur() {
      // this.form.liveinfo.forEach(i => {
      // 	i.live_name = i.item.value
      // })
    },
    //	居住人修改
    onChangeRole() {
      this.form.liveinfo.forEach(item => {
        item.live_name = ''
      });
    }
  },
  created() {
    // 获取宿舍详情
    getDormitory(this.$route.query.id).then(res => {
      this.form = JSON.parse(JSON.stringify(res.data.data).replace(/nick_name/g, 'live_name'));
      this.form.liveinfo.forEach((item, index) => {
        delete item.dormitory_id;
        delete item.id;
      });
      this.form.campus = String(this.form.campus);
      this.form.buliding_id = String(this.form.buliding_id);
      this.form.floor = String(this.form.floor);
      buliding(this.form.campus).then(res => {
        this.buildingArr = res.data.data;
        this.buildingArr.forEach(i => {
          if (i.id === this.form.buliding_id) {
            this.floorArr = [];
            for (let index = i.floor_min; index <= i.floor_max; index++) {
              if (i.floor_min == i.floor_max) {
                this.floorArr.push(0);
              }
              if (index != 0) {
                this.floorArr.push(index);
              }
            }
          }
        });
      });
    });
    // 人员类型
    roleYype().then(res => {
      this.role = res.data.data;
      for (const key in this.role) {
        if (key == 3) {
          delete this.role[key];
        }
      }
    });
    // 获取校区
    this.campusArr = this.$store.getters.userInfo.school_arr;
  },
};
</script>

<style scoped>
el-input {
  width: 100rem;
}
</style>
